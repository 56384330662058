import * as THREE from 'three'
import gsap from 'gsap'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js'
import { Mesh } from 'three'
import vertexShader from './shaders/vertex.glsl'
import fragmentShader from './shaders/fragment.glsl'
import typefaceFont from "three/examples/fonts/helvetiker_regular.typeface.json"
import {FontLoader} from "three/examples/jsm/loaders/FontLoader.js"
import {TextGeometry} from "three/examples/jsm/geometries/TextGeometry.js"



//have stuff come from left according to scroll.

//console.log(vertexShader);
//console.log(fragmentShader)
const scene = new THREE.Scene()

let glassMaterial = new THREE.MeshPhysicalMaterial({
    transmission: 1,
    thickness:3,
    roughness: 0.15,
    clearcoat: 1,
});

const gltfLoader = new GLTFLoader();
let dragonMesh;
gltfLoader.load("models/dragon.glb",(gltf)=>{
    /*gltf load model geometry*/
    const dragon = gltf.scene.children[1];
    const geometry = dragon.geometry.clone();

    dragonMesh = new THREE.Mesh(geometry, glassMaterial);

    dragonMesh.scale.set(0.135,0.135,0.135);
    dragonMesh.rotation.x = 1.3;
    dragonMesh.position.set(2,-0.5,0)

    scene.add(dragonMesh);
})

//texture loader.

const textureLoader = new THREE.TextureLoader();
const colorfulMap = textureLoader.load("textures/colorfulSwirl.jpg");

const planeGeo = new THREE.PlaneGeometry(1.6,1.6);
const planeMat = new THREE.MeshStandardMaterial({
    map:colorfulMap,
})
const planeMesh = new THREE.Mesh(planeGeo,planeMat);
planeMesh.position.x = 2;
planeMesh.position.z = -1;

scene.add(planeMesh);

const floor = new THREE.PlaneGeometry(30,21,40,40);
const floorMat = new THREE.RawShaderMaterial({
    vertexShader: vertexShader,
    fragmentShader: fragmentShader,
    wireframe:true,
    uniforms:{
        uFrequency:{value: new THREE.Vector2(10.0,5.0)},
        uTime:{value: 0}
    }
})
const floorMesh = new THREE.Mesh(floor,floorMat);
floorMesh.rotation.x = -Math.PI/2
floorMesh.position.y = -1;
scene.add(floorMesh);

/**
 * Debug
 */


/**
 * Base
 */
// Canvas
const canvas = document.querySelector('canvas.webgl')


//objectsDistance variable and choose a random value like 2.
const objectsDistance = 4;


const directionalLight = new THREE.DirectionalLight("yellow",1.2);
directionalLight.position.set(1,2,0);
const dlHelper = new THREE.DirectionalLightHelper(directionalLight);
scene.add(directionalLight);

const ambientLight = new THREE.AmbientLight("white", 0.8)
scene.add(ambientLight)



//particles for depth.

let numParticles = 500;
let arr = new Float32Array(numParticles * 3);
let rgbArr = new Float32Array(numParticles *3);

for(let i = 0; i < numParticles; i++){
    let current = i * 3;
    arr[current + 0] = (Math.random() - 0.5) * 10 + 7//x
    arr[current + 1] = (Math.random() - 0.5) * 10//y
    arr[current + 2] = (Math.random() - 0.5) * 10//z

    rgbArr[current + 0] = Math.random(); //r
    rgbArr[current + 1] = Math.random(); //g
    rgbArr[current + 2] = Math.random(); //b

}

const particlesGeometry = new THREE.BufferGeometry();
particlesGeometry.setAttribute("position", new THREE.BufferAttribute(arr,3));
particlesGeometry.setAttribute("color", new THREE.BufferAttribute(rgbArr,3))


const particleMaterial = new THREE.PointsMaterial({
    vertexColors:true,
    size: 0.05,
    sizeAttenuation: true
});

const particles = new THREE.Points(particlesGeometry, particleMaterial );
scene.add(particles);


/**
 * Sizes
 */
const sizes = {
    width: window.innerWidth,
    height: window.innerHeight
}

window.addEventListener('resize', () =>
{
    // Update sizes
    sizes.width = window.innerWidth
    sizes.height = window.innerHeight

    // Update camera
    camera.aspect = sizes.width / sizes.height
    camera.updateProjectionMatrix()

    

    // Update renderer
    renderer.setSize(sizes.width, sizes.height)
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
})


//group

const cameraGroup = new THREE.Group();

scene.add(cameraGroup);
/**
 * Camera
 */
// Base camera
const camera = new THREE.PerspectiveCamera(35, sizes.width / sizes.height, 0.1, 100)

if (sizes.width >=300 && sizes.width <=500){
    camera.position.x = -0;
}

camera.position.z = 6

cameraGroup.add(camera)

/**
 * Renderer
 */
const renderer = new THREE.WebGLRenderer({
    canvas: canvas,
    alpha: true
})


renderer.setSize(sizes.width, sizes.height)
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))



let scrollY = window.scrollY;

window.addEventListener("scroll", ()=>{
    scrollY = window.scrollY;
})

const cursor = {
    x:0,
    y:0,
}

window.addEventListener("mousemove",(e)=>{
   cursor.x = e.clientX/sizes.width - 0.5
   cursor.y = -(e.clientY/sizes.height - 0.5);

})


/* artificial intersection observer
let currentSection = 0;

window.addEventListener("scroll", ()=>{
    const newSection = Math.round(window.scrollY/sizes.height);

    if(currentSection != newSection){
        currentSection = newSection;
        gsap.to(
            meshes[currentSection].rotation,{
                duration: 1.5,
                ease: "power2.out",
                x:"+=6",
                y:"+=3",

        })
    }
})*/

const fontLoader = new FontLoader();
const coolMatcap = textureLoader.load("textures/coolMatcap6.jpg")
const coolMatcap2 = textureLoader.load("textures/3.png")
const textMaterial = new THREE.MeshMatcapMaterial({
    matcap:coolMatcap,
   });

   const textMaterial2 = new THREE.MeshMatcapMaterial({
    matcap:coolMatcap2,
   });

fontLoader.load("fonts/helvetiker_regular.typeface.json",
(font)=>{
   const textGeometry = new TextGeometry("Joshua Poon",{
       font,
       size:0.3,
       height:0.2,
       curveSegments:12,
       bevelEnabled:true,
       bevelThickness:0.01,
       bevelSize: 0.02,
       bevelOffset:0,
       bevelSegments:1
   });
   const textGeometry2 = new TextGeometry("Hi, im",{
    font,
    size:0.3,
    height:0.2,
    curveSegments:12,
    bevelEnabled:true,
    bevelThickness:0.01,
    bevelSize: 0.02,
    bevelOffset:0,
    bevelSegments:1
});
const textGeometry3 = new TextGeometry("an aspiring full",{
    font,
    size:0.3,
    height:0.2,
    curveSegments:12,
    bevelEnabled:true,
    bevelThickness:0.01,
    bevelSize: 0.02,
    bevelOffset:0,
    bevelSegments:1
});

const textGeometry4 = new TextGeometry("stack dev :))",{
    font,
    size:0.3,
    height:0.2,
    curveSegments:12,
    bevelEnabled:true,
    bevelThickness:0.01,
    bevelSize: 0.02,
    bevelOffset:0,
    bevelSegments:1
});

const textGeometry5 = new TextGeometry("Skills:",{
    font,
    size:0.2,
    height:0.2,
    curveSegments:12,
    bevelEnabled:true,
    bevelThickness:0.01,
    bevelSize: 0.02,
    bevelOffset:0,
    bevelSegments:1
});
const textGeometry6 = new TextGeometry("MERN Stack",{
    font,
    size:0.3,
    height:0.2,
    curveSegments:12,
    bevelEnabled:true,
    bevelThickness:0.01,
    bevelSize: 0.02,
    bevelOffset:0,
    bevelSegments:1
});
const textGeometry7 = new TextGeometry("JavaScript",{
    font,
    size:0.3,
    height:0.2,
    curveSegments:12,
    bevelEnabled:true,
    bevelThickness:0.01,
    bevelSize: 0.02,
    bevelOffset:0,
    bevelSegments:1
});
const textGeometry8 = new TextGeometry("HTML/CSS",{
    font,
    size:0.3,
    height:0.2,
    curveSegments:12,
    bevelEnabled:true,
    bevelThickness:0.01,
    bevelSize: 0.02,
    bevelOffset:0,
    bevelSegments:1
});
const textGeometry9 = new TextGeometry("D3.js",{
    font,
    size:0.3,
    height:0.2,
    curveSegments:12,
    bevelEnabled:true,
    bevelThickness:0.01,
    bevelSize: 0.02,
    bevelOffset:0,
    bevelSegments:1
});

   const textMesh = new THREE.Mesh(textGeometry, textMaterial);
   const textMesh2 = new THREE.Mesh(textGeometry2, textMaterial);
   const textMesh3 = new THREE.Mesh(textGeometry3, textMaterial);
   const textMesh4 = new THREE.Mesh(textGeometry4, textMaterial);

   const textMesh5 = new THREE.Mesh(textGeometry5, textMaterial2);
   const textMesh6 = new THREE.Mesh(textGeometry6, textMaterial2);
   const textMesh7 = new THREE.Mesh(textGeometry7, textMaterial2);
   const textMesh8 = new THREE.Mesh(textGeometry8, textMaterial2);
   const textMesh9 = new THREE.Mesh(textGeometry9, textMaterial2);

   textMesh.position.set(-2,0.5,0)
   textMesh2.position.set(-2,1,0);
   textMesh3.position.set(-2,0,0);
   textMesh4.position.set(-2,-0.5,0);

   textMesh5.position.set(5,1.2,0);
   textMesh6.position.set(5,0.8,0);
   textMesh7.position.set(5,0.4,0);
   textMesh8.position.set(5,0,0);
   textMesh9.position.set(5,-0.4,0);

  
   scene.add(textMesh,textMesh2,textMesh3,textMesh4,
    textMesh5,textMesh6,textMesh7,textMesh8,textMesh9);
  
}
)


/**
 * Animate
 */
const clock = new THREE.Clock()
let previousTime = 0;
const tick = () =>
{
    const elapsedTime = clock.getElapsedTime()

    const deltaTime = elapsedTime - previousTime;
    previousTime = elapsedTime;

    camera.position.x = scrollY/sizes.height * 4;

    const parallaxY = cursor.y;
    const parallaxX = cursor.x;

    cameraGroup.position.x += (parallaxX - cameraGroup.position.x) * 0.01
    cameraGroup.position.y += (parallaxY - cameraGroup.position.y) * 0.01

    planeMesh.position.y = Math.sin(elapsedTime) * 0.3;
    if(dragonMesh != null){
        dragonMesh.rotation.z +=  deltaTime * 0.8;
    }

    floorMesh.material.uniforms.uTime.value = elapsedTime;
    floorMesh.position.x = -camera.position.x;


    // Render
    renderer.render(scene, camera)

    // Call tick again on the next frame
    window.requestAnimationFrame(tick)
}

tick()
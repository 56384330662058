const projectCard = document.querySelector(".projects_card");


let scrollHeight;
window.addEventListener("scroll",()=>{
    scrollHeight =window.scrollY/window.innerHeight;
   /*console.log(scrollHeight)*/
    if(scrollHeight >=2.25){
        projectCard.style.opacity = "1";

    }
    else{
        projectCard.style.opacity = "0";
    }
})
const mouse = document.getElementById("mouse");
const circle = document.querySelector(".circle");
const text = document.querySelector(".text p");

window.addEventListener("mousemove", (e) => {
  let x = e.clientX;
  let y = e.clientY;

  mouse.style.top = y + "px";
  mouse.style.left = x + "px";
  mouse.style.display = "block";

  circle.style.top = y - 20 + "px";
  circle.style.left = x - 20 + "px";
  circle.style.display = "block";
});

/*window.addEventListener("mouseout", () => {
  mouse.style.display = "none";
  circle.style.display = "none";
});*/

window.addEventListener("mousedown", () => {
  mouse.style.backgroundColor = "green";
});
window.addEventListener("mouseup", () => {
  mouse.style.backgroundColor = "";
});

text.innerHTML = text.textContent
  .split("")
  .map((letter, index) => {
    return `<span style="transform:rotate(${index * 8.3}deg)">${letter}</span>`;
  })
  .join("");